import React from 'react'
import {Link} from 'gatsby'


const Services = () => (
  <section className="section-service homepage-integrate-section homepage-ab-integrate-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <h2 className="section-heading">Services</h2>
          <hr className="hr"/>
          <div className="testimonial-quote">
            <p>Our technology firm is here to help you and your business. From any engineering needs you might have to product development, data security, data appliances, infrastructure services or staffing needs for your upcoming challenges and dreams, we're here to help you achieve your dreams.</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/iot.png" alt="Internet of Things"/>
          </div>
          <h3 className="cards-topic-title">IoT: Internet of Things</h3>
          <p className="section-subheading">We build fully managed IoT platform that lets you securely connect and process IoT data at scale. It provides the ability to analyze and visualize this data in real-time, integrate IoT data into your business applications and rapidly make operational decisions that impact your business.</p>
          <Link to="/internet-of-things" class="see-docs-link" target="_blank">know more ➜</Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/blockchain-1.png" alt="Private Blockchain Implementation"/>
          </div>
          <h3 className="cards-topic-title">Private Blockchain Implementation</h3>
          <p className="section-subheading">Businesses have sensitive information that can’t be shared with just anyone. Therefore a public permission-less blockchain solution is not appropriate because it will divulge confidential information to anyone who wishes to access it.</p>
          <Link to="/blockchain" class="see-docs-link" target="_blank">know more ➜</Link>
        </div>
      </div>

{/*
      <br/>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/credit-card-2.png" alt="Payment Processing"/>
          </div>
          <h3 className="cards-topic-title">Payment Processing: Accept Payments Anywhere</h3>
          <p className="section-subheading">Integrating out payment gateway in your platforms is super easy. With our powerful Web plugin, Android and iOS SDKs, your
            support system will be up and running in a matter of minutes.</p>
          <Link to="/payment-processing" className="see-docs-link" target="_blank">know more ➜</Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/infrastructure-services.jpg" alt="Managed Services"/>
          </div>
          <h3 className="cards-topic-title">Infrastructure</h3>
          <p className="section-subheading">Our Infrastructure Service engagements starts with an assessment of your current IT environments, data storage, data backup and disaster recovery strategies. Infrastructure Services typically include monitoring & managing IT infrastructure servers, networks, storage, and security, with a strong commitment by a detailed Service Level Agreement.</p>
          <Link to="/infrastructure" className="see-docs-link" target="_blank">know more ➜</Link>
        </div>
      </div>
*/}

      <br/>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/it-consulting.png" alt="Professional Services"/>
          </div>
          <h3 className="cards-topic-title">Consulting Services</h3>
          <p className="section-subheading">Quantic Consulting Services offers complete consulting for businesses of small, medium and large Fortune 500 corporations. Quantic Consulting Services are the best cost effective way to manage IT resources and network by a using a third party service provider.</p>
          <Link to="/consulting-services" className="see-docs-link" target="_blank">know more ➜</Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/mob-web.webp" alt="User Experiences"/>
          </div>
          <h3 className="cards-topic-title">Custom App Development</h3>
          <p className="section-subheading">We Build Applications that Look Great on Every Device. Consumers are using an ever increasing variety of platforms to access services. Designing only for one type of device will leave some of your users with a frustrating experience.
            We're here to help you build it. Digital Strategy, Mobile App, Web Apps, Design – CX/UX, e-Commerce and Dynamic Websites.</p>
          <Link to="/application-development" className="see-docs-link" target="_blank">know more ➜</Link>
        </div>
      </div>

    </div>
  </section>
);

export default Services


/*


*/
